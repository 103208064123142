import React, { useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getReports } from '../../../utils/apis';
import { groupBy } from '../../../utils/utils';
import { toast } from 'react-toastify';
import moment from 'moment';


const AudienceReviewChart = ({ selectStatistic, acitveIndex, setAcitveIndex, setAllData, parentRef, operator, range }) => {

    const [loading, setLoading] = useState(false);
    const [monthData, setMonthData] = useState({});
    const [series, setSeries] = useState([]);
    const [dataByProvider, setDataByProvider] = useState([]);
    const [categories, setCategories] = useState([]);

    const optionsTemplate = {

        chart: {
            type: 'bar',
            height: 250,
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
            foreColor: "#646A71",
            fontFamily: 'DM Sans'
        },

        grid: {
            borderColor: '#F4F5F6',
        },

        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '35%',
                borderRadius: 5,
                borderRadiusApplication: "end",
                borderRadiusWhenStacked: "last"
            },
        },
        xaxis: {
            type: 'string',
            // type: 'datetime',
            categories: [],

            labels: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'inherit',
                },
            },
            axisBorder: {
                show: false,
            },
            title: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'inherit',
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'inherit',
                },
            },
            title: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'inherit',
                }
            },
        },
        legend: {
            show: true,
            position: 'top',
            fontSize: '15px',
            labels: {
                colors: '#6f6f6f',
            },
            markers: {
                width: 8,
                height: 8,
                radius: 15

            },
            itemMargin: {
                vertical: 5
            },
        },

        colors: ['#015259', '#007D88', '#00aebd', '#015741', '#03795b', '#25cba1', '#015734', '#058350', '#14d185'],
        fill: {
            opacity: 1
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: function (value) {
                    return `€${value}`
                },
                title: {
                    formatter: (seriesName) => seriesName,
                },
            },
        }
    };

    useEffect(() => {
        let _series = [];
        let _categories = [];
        let _dataByProvider = [];
        if (monthData.length) {
            console.log("monthData:", monthData)
            for (const i in monthData) {
                let resultsGGR = 0;
                let results = monthData[i].results;
                if (results.length) {
                    for (const idx in results) {
                        console.log("results[idx]:", results[idx])
                        resultsGGR += results[idx].ggr;
                    }
                }
                _categories.push(monthData[i].date);
                _series.push(resultsGGR.toFixed(2));
            }
            setSeries([{ name: 'GGR', data: _series }]);
            setCategories(_categories);
            setDataByProvider(_dataByProvider);
        }
    }, [monthData]);

    const options = useMemo(() => {
        if (categories.length) {
            optionsTemplate.xaxis.categories = categories;
            optionsTemplate.chart.events = {
                dataPointSelection: (event, chartContext, { seriesIndex, dataPointIndex }) => {
                    selectStatistic(dataByProvider[seriesIndex][dataPointIndex]);
                    setAcitveIndex(`${seriesIndex}-${dataPointIndex}`)
                },
                mouseLeave: function (event, chartContext, { seriesIndex, dataPointIndex }) {
                    setAcitveIndex('');
                }
            };
        }
        return optionsTemplate;
        // eslint-disable-next-line
    }, [categories, dataByProvider]);

    const loadData = async () => {
        try {
            setLoading(true);
            let report;
            if (operator.current) {
                report = await getReports(`start=${moment(range.current.start).format('yyyy-MM-DD')}&end=${moment(range.current.end).format('yyyy-MM-DD')}&type=monthly&user=${operator.current.id}`);
            } else {
                report = await getReports(`start=${moment(range.current.start).format('yyyy-MM-DD')}&end=${moment(range.current.end).format('yyyy-MM-DD')}&type=monthly`);
            }
            console.log("report M:", report)
            // report = report.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            if (report.length) {
                const totalReport = {
                    providerName: 'Total',
                    totalGGR: 0,
                    totalRounds: 0,
                    totalTurnover: 0,
                    totalWin: 0,
                    uniquePlayers: 0
                }
                report.forEach(element => {
                    element.results.forEach(e => {
                        console.log("element:", e);
                        totalReport.totalGGR += e.ggr;
                        totalReport.totalRounds += e.totalRounds;
                        totalReport.totalTurnover += e.totalTurnover;
                        totalReport.totalWin += e.totalWin;
                        totalReport.uniquePlayers += e.uniquePlayers;
                    })
                });

                selectStatistic(totalReport);
                setAllData(totalReport);
                setMonthData(report)
            }
            setLoading(false);
        } catch (error) {
            toast.error(error.message)
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
        parentRef.current = loadData;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loading) {
        return <div style={{ height: 270 }} className='w-100 d-flex align-items-center justify-content-center'>Loading...</div>
    } else {
        if (!Object.keys(monthData).length) {
            return <div style={{ height: 270 }} className='w-100 d-flex align-items-center justify-content-center'>No Data</div>
        }
        return <ReactApexChart options={options} series={series} type="bar" height={270} />
    }
}

export default AudienceReviewChart
