import axios from 'axios';
// config
import { HOST_API } from '../config-global';
import { setSession, setUserSession } from '../auth/context/utils';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => {
        if (error.response.status === 401) {
            setUserSession();
            setSession();
            window.location.href = "/auth/login";
        }
        return Promise.reject((error.response && error.response.data) || 'Something went wrong')
    }
);

export default axiosInstance;

// ----------------------------------------------------------------------
export const endpoints = {
    auth: {
        ping: '/ping',
        login: '/auth/login',
        register: '/auth/register',
        forgotPassword: '/auth/forgot-password',
        resetPassword: '/auth/reset-password'
    },
    permission: '/permission',
    allPermission: '/permissions/get',
    group: '/group',
    user: '/user',
    log: '/log',
    game: '/game',
    round: '/round',
    provider: '/provider',
    gamecategory: '/gamecategory',
    notification: '/notification',
    transaction: '/transaction',
    statistic: '/statistic',
    report: '/report',
    markup: '/markup',
    percentCategory: '/percentCategory',
    currency: '/currency',
    session: '/session',
    supportticket: '/supportticket',
    supportcomment: '/supportcomment',
    upload: '/file/upload',
    file: '/file',
    financeDashboard: '/financereport',
    financeReport: '/userreport',
    operator: '/operator',
};
