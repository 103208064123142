import React, {useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {Col, Container, Nav, Row, Tab, Button, Form, InputGroup} from 'react-bootstrap';
import { toggleCollapsedNav } from '../../redux/action/Theme';
import {Calendar, RefreshCw} from 'react-feather';
import CustomerTable from './CustomerTable';
import AudienceReviewCard from './AudienceReviewCard';
import ReturningCustomersCard from './ReturningCustomersCard';
import OperatorsList from './OperatorsList';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

const Dashboard = ({ toggleCollapsedNav }) => {
    const monthlyChart = useRef(null);
    const dailyChart = useRef(null);
    const alerts = useRef(null);
    const operator = useRef(null);
    const range = useRef({
        start: moment().subtract(1, 'months').toDate(),
        end: moment().toDate(),
    });
    const changeDate = (start, end) => {
        range.current = { start: start.toDate(), end: end.toDate() };
        refreshPage();
    }
    const refreshPage = () => {
        monthlyChart.current();
        dailyChart.current();
        alerts.current();
    }
    const handleOperatorClick = (selectedOperator) => {
        operator.current = selectedOperator;
        refreshPage();
    };

    useEffect(() => {
        toggleCollapsedNav(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Helmet>
                <title> Dashboard | Slotserv - iGaming Content Provider </title>
            </Helmet>
            <Container>
                <Tab.Container activeKey="overview">
                    {/* Page Header */}
                    <div className="hk-pg-header pg-header-wth-tab pt-7">
                        <div className="d-flex">
                            <div className="d-flex flex-wrap justify-content-between flex-1">
                                <div className="mb-lg-0 mb-2 me-8">
                                    <h1 className="pg-title">Welcome back</h1>
                                    <p>Explore your operations live statistics right away!</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <InputGroup className="w-270p me-2" >
                                <span className="input-affix-wrapper">
                                    <span className="input-prefix">
                                        <span className="feather-icon">
                                            <Calendar />
                                        </span>
                                    </span>
                                    <DateRangePicker
                                        initialSettings={{
                                            timePicker: true,
                                            startDate: range.current.start,
                                            endDate: range.current.end,
                                            locale: {
                                                format: 'M/DD hh:mm A',
                                            },
                                        }}
                                        onCallback={changeDate}
                                    >
                                        <Form.Control type="text" name="datetimes" size="sm" />
                                    </DateRangePicker>
                                </span>
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                        <Nav variant="tabs" className="nav-light nav-line">
                            <OperatorsList onOperatorClick={handleOperatorClick} />
                            <Button size='sm' className='ms-auto mb-1' onClick={refreshPage}>
                                <RefreshCw height={16} className='me-1' />
                                Refresh</Button>
                        </Nav>
                    </div>
                    {/* /Page Header */}
                    {/* Page Body */}
                    <div className="hk-pg-body pb-10">
                        <Tab.Content>
                            <Tab.Pane eventKey="overview" >
                                <Row>
                                    <Col xxl={9} lg={8} md={7} className="mb-md-4 mb-3">
                                        <AudienceReviewCard parentRef={monthlyChart} operator={operator} range={range} />
                                    </Col>
                                    <Col xxl={3} lg={4} md={5} className="mb-md-4 mb-3">
                                        <ReturningCustomersCard parentRef={dailyChart} operator={operator} range={range}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-md-4 mb-3">
                                        <CustomerTable parentRef={alerts} />
                                    </Col>
                                </Row>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                    {/* /Page Body */}
                </Tab.Container>
            </Container>
        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(Dashboard);
