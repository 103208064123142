import React, {useEffect, useState} from "react";
import {getOperators} from "../../utils/apis"
import {Nav} from "react-bootstrap";
const OperatorsList = ({ onOperatorClick }) => {
    const [operators, setOperators] = useState([]);
    const [selectedOperator, setSelectedOperator] = useState(null); // State to track selected operator

    useEffect(() => {
        getOperators().then(res => {
            setOperators(res);
        });
    },[]);

    const onClick = (operator) => {
        // Set the selected operator
        setSelectedOperator(operator);
        // Call the parent component's callback function
        onOperatorClick(operator);
    }


    return (
        <React.Fragment>
            <Nav.Item key={0}>
                <Nav.Link
                    className={selectedOperator === null ? "active" : ""}
                    onClick={() => onClick(null)}
                >
                    <span className="nav-link-text">Overview</span>
                </Nav.Link>
            </Nav.Item>
            {operators.map((operator, index) => (
                <Nav.Item key={index + 1} onClick={() => onClick(operator)}>
                    <Nav.Link
                        className={
                            selectedOperator?.id === operator.id ? "active" : ""
                        }
                    >
                        <span className="nav-link-text">{operator.firstName}</span>
                    </Nav.Link>
                </Nav.Item>
            ))}
        </ React.Fragment>
    )
}
export default OperatorsList;
